import * as Yup from "yup";
import moment from "moment";
import { setToStartOfDay } from "../index";
import { hotelTypes } from "@/constants";

const today = setToStartOfDay(moment()).toDate();

export const availabilityValidationSchema = Yup.object().shape({
  hotelType: Yup.string()
    .required("form_validation.required")
    .oneOf(hotelTypes, "form_validation.type"),
  startDate: Yup.date()
    .min(today, "form_validation.date.min")
    .required("form_validation.required"),
  endDate: Yup.date()
    .test(
      "is-greater-than-start",
      "form_validation.date.refStartDate",
      function (endDate) {
        const { startDate } = this.parent;
        if (!startDate || !endDate) return false;
        const start = setToStartOfDay(moment(startDate));
        const end = setToStartOfDay(moment(endDate));
        return end.isSameOrAfter(start);
      }
    )
    .required("form_validation.required")
});
