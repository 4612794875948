import { partners } from "@/constants/footer";
import { useTranslation } from "react-i18next";

export default function PartnerList() {
	const { t } = useTranslation();
	return (
		<div className='col col-lg-3 col-md-6 col-sm-12 col-12'>
			<div className='widget link-widget px-4 '>
				<div className='widget-title'>
					<h3>{t("footer.partenaires.titre")}</h3>
				</div>
				<ul>
					{partners.map((partner, i) => (
						<li key={i}>
							<a
								target='_blank'
								href={partner.link}
								rel='noreferrer'>
								{partner.name}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
