const PageTitle = (props) => {
  return (
    <div className="texte my-5">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h2 className="display-1 my-3 text-center">
              <b>{props.pageTitle}</b>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
