import axiosInstance from "@/utils/axios";
import moment from "moment";

// POST RESERVATION
export const faireReservation = async (
  nom,
  prenom,
  gender,
  email,
  numero,
  breakfast,
  navette,
  parking,
  idRooms,
  startDate,
  endDate,
  adulte,
  enfant,
  note,
  lang,
  diffDay,
  totalPrice,
  totalBreakfastPrice,
  roomPrice,
  navettePrice
) => {
  const response = await axiosInstance.post(
    "/reservation/reserver",
    {
      client: {
        nom,
        prenom,
        gender,
        email,
        numero,
      },
      breakfast,
      navette,
      parking,
      idRooms,
      startDate,
      endDate,
      adulte,
      enfant,
      note,
      diffDay,
      totalPrice,
      totalBreakfastPrice,
      roomPrice,
      numPeopleToEatBreakfast: breakfast,
      navettePrice,
    },
    {
      params: {
        lang,
      },
    }
  );
  return response.data;
};

export const getReservation = async (id) => {
  return await axiosInstance
    .get(`/reservation/getReservation/` + id, {})
    .then((reservation) => {
      return reservation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getReservation2Date = async (start, end) => {
  return await axiosInstance
    .get("/reservation/getReservation2Date", {
      params: {
        checkIn: moment(start).toISOString(),
        checkOut: moment(end).toISOString(),
      },
    })
    .then((listeReservation) => {
      return listeReservation.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getReservationOccupe = async () => {
  return await axiosInstance
    .get("/reservation/getReservationOccupe", {})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const checkSessionPetitDej = async (nom, idChambre) => {
  return await axiosInstance
    .get("reservation/checkSessionPetitDej", {
      params: {
        nom: nom,
        idChambre: idChambre,
      },
    })
    .then((listeChambre) => {
      return listeChambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertPetitDej = async (
  numero,
  viennoiserie,
  oeufs,
  chaude,
  froide,
  autre,
  date
) => {
  await axiosInstance.post("reservation/insertPetitDej", {
    autre,
    chaude,
    date,
    froide,
    oeufs,
    roomId: numero,
    viennoiserie,
  });
};

export const getPetitDej = async () => {
  return await axiosInstance
    .get("/petitDej/getPetitDej", {})
    .then((listePetitDej) => {
      return listePetitDej.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
