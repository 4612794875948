import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
const supportedLanguages = ["en", "fr", "zh"];
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .on("initialized", (options) => {
    const languageFromUrl = getLanguageFromUrl();
    const selectedLanguage = supportedLanguages.includes(languageFromUrl)
      ? languageFromUrl
      : "fr";

    i18n.changeLanguage(selectedLanguage);
  })

  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    whitelist: supportedLanguages,
    fallbackLng: "fr",
    lng: "fr",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
    },
    react: {
      useSuspense: true,
    },
  });

// Function to get language from the URL
const getLanguageFromUrl = () => {
  const pathSegments = window.location.pathname.split("/");
  const potentialLanguage = pathSegments[1]; // Assuming language is the first segment
  return supportedLanguages.includes(potentialLanguage)
    ? potentialLanguage
    : null;
};

export default i18n;
