import axiosInstance from "@/utils/axios";

//checkChambreDispo
export const checkChambreDispo = async (
  checkIn,
  checkOut,
  adulte,
  enfant,
  idChambre
) => {
  let data = null;
  try {
    const response = await axiosInstance.get("/hotel/checkChambreDispo", {
      params: {
        checkIn,
        checkOut,
        adulte: adulte,
        enfant: enfant,
        idChambre: idChambre,
      },
    });
    data = response.data;
  } catch (err) {
    console.error(err);
  }
  return data;
};
//GETCHAMBRE
export const getChambre = async () => {
  return await axiosInstance
    .get("/hotel/getChambre")
    .then((listChambre) => {
      return listChambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//GET_ONE_CHAMBRE
export const getOneChambre = async () => {
  return await axiosInstance
    .get("/hotel/getOneChambre")
    .then((chambre) => {
      return chambre.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//GET_HOTEL_TYPE
export const getHotelTypes = async () => {
  let data = [];
  try {
    const response = await axiosInstance.get("/hotel/getHotelType");
    data = response.data;
  } catch (err) {
    console.log(err);
  }
  return data;
};

export async function getAvailableRooms(params = {}) {
  const { startDate, endDate, roomType } = params;

  const queryString = new URLSearchParams({
    startDate,
    endDate,
    roomType,
  }).toString();

  const response = await axiosInstance.get(`/hotel/available?${queryString}`);
  return response.data;
}

export async function checkRoomAvailability(params = {}) {
  const { roomId, startDate, endDate } = params;
  const response = await axiosInstance.get(`/hotel/available/${roomId}`, {
    params: {
      startDate,
      endDate,
    },
  });

  return response.data;
}

// GET_CHAMBRE_DISPO_FILTER
export const getChambreDispoFilter = async (
  checkIn,
  checkOut,
  adulte,
  enfant,
  type
) => {
  let data = [];
  try {
    const response = await axiosInstance.get("/hotel/getChambreDispoFilter", {
      params: {
        checkIn: checkIn,
        checkOut: checkOut,
        adulte: adulte,
        enfant: enfant,
        type,
      },
    });
    data = response.data;
  } catch (err) {
    console.log(err);
  }
  return data;
};
// GET_CHAMBRE_PAR_TYPE
export const getChambreParType = async (typeHotel) => {
  return await axiosInstance
    .get("/hotel/getChambreParType", {
      params: {
        type: typeHotel,
      },
    })
    .then((listHotel) => {
      return listHotel.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// CREATE_CHAMBRE
export const createChambre = async (objetHotel) => {
  await axiosInstance
    .post("/hotel/creerChambre", {
      data: objetHotel,
    })
    .then(() => {
      window.alert("type d'hotel" + objetHotel.categorie + " créé");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getRoomsByHotelType = async (type) => {
  const response = await axiosInstance.get(`/hotel/types/${type}`);
  return response.data;
};

export const getRoomsByCategoryId = async (id) => {
  const response = await axiosInstance.get(`/hotel/categories/${id}`);
  return response.data;
};
