import LoadingButton from "@/components/LoadingButton";
import ScrollTo from "@/components/ScrollTo";
import { useInformations } from "@/hooks/informations";
import { useReservationConfirmation } from "@/hooks/reservation";
import { faireReservation } from "@/services/Reservation";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function PersonalDetails(props) {
  const { t } = useTranslation();
  const { firstName, lastName, email, phoneNumber } = props;

  return (
    <>
      <h4 className="text-center my-4">{t("titre.personalDetails")}</h4>
      <Table className="text-black">
        <TableBody>
          <TableRow>
            <TableCell>{t("personalInfos.firstName")}</TableCell>
            <TableCell align="right">
              <b>{firstName}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("personalInfos.lastName")}</TableCell>
            <TableCell align="right">
              <b>{lastName}</b>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("personalInfos.email")}</TableCell>
            <TableCell align="right">
              <b>{email}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("personalInfos.phone")}</TableCell>
            <TableCell align="right">
              <b>+{phoneNumber}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default function ReservationConfirmation() {
  const [loading, setLoading] = useState(false);
  const { values, rooms, search, diffDay } = useReservationConfirmation();
  const { t, i18n } = useTranslation();
  const [navetteInfos, breakfastInfos] = useInformations(
    "navettePrice",
    "breakfastPrice"
  );
  const [navettePrice, breakfastPrice] = [
    parseInt(navetteInfos.value),
    parseInt(breakfastInfos.value),
  ];

  const navigate = useNavigate();

  const breakfastValue = values.breakfast;

  const getRoomPrice = () => rooms.reduce((prev, acc) => acc.prix + prev, 0);

  const displayTotalBreakfastPrice = () => {
    return breakfastValue * breakfastPrice * diffDay;
  };

  const getTotalPrice = () => {
    const price = rooms.reduce((prev, acc) => acc.prix + prev, 0);
    return (
      price * diffDay +
      values.navette.data.length * navettePrice +
      displayTotalBreakfastPrice()
    );
  };

  const displayRoomsTitle = () => {
    return [
      ...new Set(
        rooms.map((room) => t(`categories.${room.idCategorie.intitule}`))
      ),
    ].join(", ");
  };

  const displayBigBed = () => {
    return rooms.reduce((prev, acc) => acc.idCategorie.grandLit + prev, 0);
  };

  const displaySmallBed = () => {
    return rooms.reduce((prev, acc) => acc.idCategorie.petitLit + prev, 0);
  };

  const displayPrice = () => {
    return rooms.reduce((prev, acc) => acc.prix + prev, 0);
  };

  const displayRoomType = () => {
    return rooms.map((r) => t(`rooms.${r.idHotelType.type}`)).join(", ");
  };

  const displayNavettePrice = () => {
    return values.navette.data.length * navettePrice;
  };

  const handleSubmit = async () => {
    setLoading(true);
    let data;
    try {
      window.localStorage.removeItem("reservationFormData");
      data = await faireReservation(
        values.firstName,
        values.lastName,
        values.gender,
        values.email,
        values.phoneNumber,
        breakfastValue,
        values.navette,
        values.parking,
        rooms.map((room) => room._id),
        search.startDate,
        search.endDate,
        values.adults,
        values.kids,
        values.note,
        i18n.resolvedLanguage,
        diffDay,
        getTotalPrice(),
        displayTotalBreakfastPrice(),
        getRoomPrice(),
        displayNavettePrice()
      );
      navigate(`/${t("url.reservation")}/email`, {
        state: { sent: true },
      });
    } catch (err) {
      navigate(`/${t("url.reservation")}/email`, {
        state: { sent: false }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollTo top={400}>
      <div className="row justify-content-center">
        <div className="col-11 col-md-9 col-lg-6">
          <div className="mx-auto mb-3">
            <h1 className="text-center mt-3">{t("formulaire.modal.title")}</h1>
            <Grid className="cartStatus">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid className="cartTotals">
                    <PersonalDetails {...values} />
                    <h4 className="text-center my-4">{t("titre.h4")}</h4>
                    <Table>
                      <TableBody>
                        <TableRow className="totalProduct">
                          <TableCell>{t("titre.type")}</TableCell>
                          <TableCell align="right">
                            <b>{displayRoomType()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow className="totalProduct">
                          <TableCell>{t("chambre")}</TableCell>
                          <TableCell align="right">
                            <b>{displayRoomsTitle()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.gl")}</TableCell>
                          <TableCell align="right">
                            <b>{displayBigBed()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.pl")}</TableCell>
                          <TableCell align="right">
                            <b>{displaySmallBed()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.date")}</TableCell>
                          <TableCell align="right">
                            <b>{search.startDate}</b> {t("titre.au")}{" "}
                            <b>{search.endDate}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.pax")}</TableCell>
                          <TableCell align="right">
                            {values.adults} {t("titre.adulte")} {t("titre.et")}{" "}
                            {values.kids} {t("titre.enfant")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("services.parking")}</TableCell>
                          <TableCell align="right">
                            {values.parking
                              ? t("formulaire.oui")
                              : t("formulaire.non")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.breakfast")}</TableCell>
                          <TableCell align="right">
                            Ar <b>{displayTotalBreakfastPrice()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.nuitée")}</TableCell>
                          <TableCell align="right">
                            Ar <b>{displayPrice()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("titre.navette")}</TableCell>
                          <TableCell align="right">
                            Ar <b>{displayNavettePrice()}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h2>{t("titre.total")}</h2>
                          </TableCell>
                          <TableCell align="right">
                            <h2>
                              Ar <b>{getTotalPrice()}</b>{" "}
                            </h2>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", marginTop: "10px" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item className="my-4">
                  <LoadingButton
                    loading={loading}
                    text={t("confirm")}
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </ScrollTo>
  );
}
