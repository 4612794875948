import ServiceItem from "@/components/Footer/ServiceItem";
import { services } from "@/constants/footer";
import { useTranslation } from "react-i18next";

export default function ServiceList() {
  const { t } = useTranslation();

  return (
    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
      <div className="widget link-widget s1 px-4">
        <div className="widget-title">
          <h3>{t("services.titre")}</h3>
        </div>
        <ul>
          {services(t).map((service, i) => {
            return <ServiceItem service={service} key={i} />;
          })}
        </ul>
      </div>
    </div>
  );
}
