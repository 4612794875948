import HeaderTopbar from "@/components/HeaderTopbar";
import MobileMenu from "@/components/MobileMenu";
import { useArrangedHotelType } from "@/hooks/hotelType";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./index.css";
import { LayoutContext } from "../Layout";
import styles from "./index.module.css";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const [hotelTypes] = useArrangedHotelType();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { isFixed } = useContext(LayoutContext);

  return (
    <header id="header" className={props.topbarBlock}>
      <HeaderTopbar />
      <div
        className={`wpo-site-header ${props.hclass}`}
        style={{
          backgroundColor: !isFixed ? "rgba(0, 0, 0, 0.2)" : "#303443",
          backdropFilter: !isFixed ? "blur(2px)" : "",
        }}
      >
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobile-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6 p-0">
                <div className={`navbar-header`}>
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand logo"
                    to={`/${i18n.resolvedLanguage}`}
                  >
                    <img
                      src={props.Logo}
                      width="55%"
                      height="55%"
                      alt="Valiha hotel logo"
                      className={styles.logo}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 col-6 offset-lg-2 ml-auto">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <FaXmark />
                  </button>
                  <ul
                    className="nav navbar-nav mb-2 mb-lg-0"
                    style={{
                      flexWrap: "nowrap",
                    }}
                  >
                    <li className="menu-item-has-children">
                      <Link to={`/${i18n.resolvedLanguage}/`}>
                        {t("menu.accueil")}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${t("url.reservation")}`}>
                        {t("menu.reservation")}
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          ClickHandler();
                        }}
                        href={`/${t("url.blogs")}`}
                      >
                        {t("menu.blog")}
                      </a>
                    </li>

                    <li className="menu-item-has-children">
                      <Link
                        id="hebergement"
                        onClick={() => {
                          window.localStorage.setItem("typeHotel", "");
                          ClickHandler();
                        }}
                        to={`/${t("url.hebergement")}`}
                      >
                        {t("menu.hebergement")}
                      </Link>
                      <ul className="sub-menu">
                        {hotelTypes.map((hotelType) => (
                          <li key={hotelType._id}>
                            <Link
                              onClick={() => {
                                window.localStorage.setItem(
                                  "typeHotel",
                                  hotelType.type
                                );
                                window.localStorage.setItem(
                                  hotelType.keyType,
                                  hotelType.type
                                );
                                ClickHandler();
                              }}
                              to={
                                `/${t("url.hebergement")}/` + hotelType.keyType
                              }
                            >
                              {hotelType.type}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={`/${t("url.location")}`}>
                        {t("menu.location")}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={`/${t("url.contact")}`}>
                        {t("menu.contact")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
