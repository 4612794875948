import ReservationInputs from "@/components/Reservation/ReservationForm/ReservationInputs";
import { useTranslation } from "react-i18next";

export default function PersonalInformation() {
  const { t } = useTranslation();

  return (
    <div className="col col-12">
      <h4 className="mb-4">{t("titre.personalInfo")}</h4>
      <ReservationInputs />
    </div>
  );
}
