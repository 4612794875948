import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/animate.css";
import "./css/flaticon.css";
import "./css/font-awesome.min.css";
import "./css/themify-icons.css";
import reportWebVitals from "./reportWebVitals";
import "./sass/style.scss";

import { Provider } from "react-redux";
import SuspenseLoader from "./components/SuspenseLoader";
import "./i18n";
import { store } from "./store";
import { InformationsProvider } from "./hooks/informations";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Suspense fallback={<SuspenseLoader />}>
      <Provider store={store}>
        <InformationsProvider>
          <App />
        </InformationsProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
