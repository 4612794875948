import InputErrorMessage from "@/components/Contact/ContactForm/InputErrorMessage";
import { navetteDefaultValue } from "@/constants";
import { useSearchReservation } from "@/hooks/reservation";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function Navette({ formik }) {
  const { t } = useTranslation();
  const { search } = useSearchReservation();

  const handleChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      formik.setFieldValue("parking", false);
      formik.setFieldValue("navette.data", [
        navetteDefaultValue(moment(search.startDate, "DD/MM/YYYY").toDate()),
      ]);
    } else {
      formik.setFieldValue("navette.data", []);
    }
    formik.setFieldValue("navette.checked", checked);
  };

  return (
    <div className="col col-lg-3 col-6">
      <div className="d-flex justify-content-start align-items-center">
        <label className="label-navette-checkbox" htmlFor="radio2">
          {t("formulaire.navette")} :
        </label>
        <div className="d-flex align-items-center ms-3">
          <input
            id="label-navette-checkbox"
            type="checkbox"
            name="navette.checked"
            checked={
              formik.values.parking ? false : formik.values.navette.checked
            }
            onChange={handleChange}
          />
          <label
            className="form-check-label mx-1"
            htmlFor="label-navette-checkbox"
          >
            {t("formulaire.oui")}
          </label>
        </div>
      </div>
      <InputErrorMessage formik={formik} name="navette.checked" />
    </div>
  );
}
