import { menus } from "@/constants";
import { useTranslation } from "react-i18next";
import MenuListItem from "../MenuListItem";

export default function MenuList({ setMenuState }) {
  const { t } = useTranslation();

  return (
    <ul className="responsivemenu">
      {menus(t).map((item, i) => {
        return <MenuListItem key={i} item={item} setMenuState={setMenuState} />;
      })}
    </ul>
  );
}
