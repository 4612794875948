import InputErrorMessage from "@/components/Contact/ContactForm/InputErrorMessage";
import { useTranslation } from "react-i18next";

export default function CheckboxParking({ formik }) {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      formik.setFieldValue("navette.checked", false);
      formik.setFieldValue("navette.data", []);
    }
    formik.handleChange(e);
  };

  return (
    <div className="col col-lg-3 col-6">
      <div className="d-flex justify-content-start align-items-center">
        <label className="form-check-label" htmlFor="parking">
          {t("services.parking")} :{" "}
        </label>
        <div className="d-flex align-items-center ms-3">
          <input
            id="parking"
            name="parking"
            type="checkbox"
            checked={formik.values.parking}
            onChange={handleChange}
          />
          <label className="form-check-label mx-1" htmlFor="parking">
            {t("formulaire.oui")}
          </label>
        </div>
      </div>
      <InputErrorMessage formik={formik} name="parking" />
    </div>
  );
}
