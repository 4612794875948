import { getHotelTypes } from "@/services/Chambre";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { hotelTypes as data } from "@/constants";

export default function useHotelType() {
	const [hotelTypes, setHotelTypes] = useState([data]);
	return [hotelTypes, setHotelTypes];
}

export function useArrangedHotelType() {
	const { t } = useTranslation();
	const [hotelTypes, setHotelTypes] = useState([]);

	const listeKeyType = useMemo(
		() => [t("url.petitDej"), t("url.appart")],
		[t],
	);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getHotelTypes();
			const dataArranged = [];
			for (let i = 0; i < data.length; i++) {
				dataArranged.push({
					_id: data[i]._id,
					type: data[i].type,
					keyType: listeKeyType[i],
				});
			}
			setHotelTypes(dataArranged);
		};
		fetchData();
	}, [listeKeyType]);

	return [hotelTypes, setHotelTypes];
}
