import * as Yup from "yup";

export const getReservationValidationSchema = (startDate, endDate) =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, "form_validation.reservation.firstName.min")
      .required("form_validation.reservation.firstName.required"),
    lastName: Yup.string()
      .min(2, "form_validation.reservation.lastName.min")
      .required("form_validation.reservation.lastName.required"),
    email: Yup.string()
      .email("form_validation.reservation.email.invalid")
      .required("form_validation.reservation.email.required"),
    adults: Yup.number()
      .min(1, "form_validation.reservation.adults.min")
      .required("form_validation.reservation.adults.required"),
    kids: Yup.number()
      .min(0, "form_validation.reservation.kids.min")
      .required("form_validation.reservation.kids.required"),
    phoneNumber: Yup.string().required(
      "form_validation.reservation.phoneNumber.required"
    ),
    breakfast: Yup.number(),
    navette: Yup.object().shape({
      checked: Yup.boolean().required(
        "form_validation.reservation.navette.check.required"
      ),
      data: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(
            "form_validation.reservation.navette.name.required"
          ),
          selection: Yup.string().required(
            "form_validation.reservation.navette.selection.required"
          ),
          direction: Yup.string().required(
            "form_validation.reservation.navette.direction.required"
          ),
          flightNumber: Yup.string().required(
            "form_validation.reservation.navette.flightNumber.required"
          ),
          timeArrival: Yup.string().required(
            "form_validation.reservation.navette.timeArrival.required"
          ),
          dateArrival: Yup.date()
            .min(
              startDate,
              "form_validation.reservation.navette.dateArrival.refStartDate"
            )
            .max(
              endDate,
              "form_validation.reservation.navette.dateArrival.refEndDate"
            )
            .required(
              "form_validation.reservation.navette.dateArrival.required"
            ),
        })
      ),
    }),
    parking: Yup.boolean().required("form_validation.required"),
  });
