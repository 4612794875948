import ReservationFormSection from "@/components/Reservation/ReservationFormSection";
import PageTitle from "@/components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { ErrorScrollContext } from "@/utils/error-scroll";
import { ReservationFormProvider } from "@/hooks/reservation";

const Formulaire = () => {
  const { t } = useTranslation();
  const personalInfosErrorView = useRef(null);
  const shuttleErrorView = useRef(null);

  return (
    <ReservationFormProvider>
      <ErrorScrollContext.Provider
        value={{
          personalInfosErrorView,
          shuttleErrorView,
        }}
      >
        <div ref={personalInfosErrorView}>
          <PageTitle
            pageTitle={t("grand_titre.reserver")}
            pagesub={"Formulaire"}
          />
          <ReservationFormSection />
        </div>
      </ErrorScrollContext.Provider>
    </ReservationFormProvider>
  );
};

export default Formulaire;
