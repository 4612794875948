import Header from "@/components/Header";
import React from "react";

export default function Navbar(props) {
  const handleScroll = () => {
    if (document.documentElement.scrollTop > 80) {
      document.getElementById("Layout-header-top").classList.add("active");
    } else {
      document.getElementById("Layout-header-top").classList.remove("active");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={"fixed-navbar"} id="Layout-header-top">
      <Header
        Logo={props.Logo}
        topbarBlock={props.topbarBlock}
        hclass={props.hclass}
      />
    </div>
  );
}
