import { IoIosClose } from "react-icons/io";
import BreakfastInputs from "../BreakfastInputs";

export default function BreakfastInputItem({ index, formik, breakfast }) {
  return (
    <>
      <div className="position-relative">
        <BreakfastInputs index={index} formik={formik} breakfast={breakfast} />
      </div>
    </>
  );
}
