import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";
import { FaArrowUp } from "react-icons/fa6";
import { useState, useEffect } from "react";

const ScrollBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="col-lg-12"
      style={{ display: isVisible ? "block" : "none" }}
    >
      <div className="header-menu">
        <ul className="smothscroll">
          <li>
            <AnchorLink href="#scrool">
              <FaArrowUp size={16} />
            </AnchorLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ScrollBar;
