import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoadedRoutes from "@/router/lazy-loaded";
import "./sass/style.scss";

const App = () => {
  return (
    <div className="App" id="scrool">
      {/* <Routes /> */}
      <LazyLoadedRoutes />
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};

export default App;
