import Copyright from "./Copyright";
import FooterContact from "./FooterContact";
import PartnerList from "./PartnerList";
import ServiceList from "./ServiceList";
import FooterLinks from "./FooterLinks";

const Footer = () => {
	return (
		<footer className='wpo-site-footer'>
			<div className='wpo-upper-footer'>
				<div className='container'>
					<div className='row'>
						<FooterLinks />
						<ServiceList />
						<PartnerList />
						<FooterContact />
					</div>
				</div>
			</div>
			<Copyright />
		</footer>
	);
};

export default Footer;
