import LazyLoadImage from "@/components/LazyLoadImage";
import PageTitleImg from "@/images/page-title.webp";
import React from "react";

function BannerImage() {
  return (
    <div>
      <LazyLoadImage
        src={PageTitleImg}
        hash="LWKw,@xuMxXT?wt7bajuNexanNs:"
        width={1920}
        height={500}
        cls="wpo-breadcrumb-area"
      />
    </div>
  );
}

export default React.memo(BannerImage);
