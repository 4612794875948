import { useReservationForm, useSearchReservation } from "@/hooks/reservation";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import "./index.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ReservationSum from "../ReservationSum";
import { useInformations } from "@/hooks/informations";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function ReservationDetail() {
  const { t } = useTranslation();
  const { rooms, search, diffDay } = useSearchReservation();
  const { formik, handlePaxChange } = useReservationForm();
  const [breakfastInfos, navetteInfos] = useInformations(
    "breakfastPrice",
    "navettePrice"
  );
  const [navettePrice, breakfastPrice] = [
    parseInt(navetteInfos.value),
    parseInt(breakfastInfos.value),
  ];

  const displayRoomsTitle = () => {
    return [
      ...new Set(
        rooms.map((room) => t(`categories.${room.idCategorie.intitule}`))
      ),
    ].join(", ");
  };

  const displayBigBed = () => {
    return rooms.reduce((prev, acc) => acc.idCategorie.grandLit + prev, 0);
  };

  const displaySmallBed = () => {
    return rooms.reduce((prev, acc) => acc.idCategorie.petitLit + prev, 0);
  };

  const displayPrice = () => {
    return rooms.reduce((prev, acc) => acc.prix + prev, 0);
  };

  const displayBreakfastPrice = () => {
    return formik.values.breakfast * breakfastPrice * diffDay;
  };

  const displayRoomType = () => {
    return rooms.map((r) => t(`rooms.${r.idHotelType.type}`)).join(", ");
  };

  return (
    <Grid className="cartTotals">
      <h4 className="mt-4 mb-3">{t("titre.h4")}</h4>
      <Table>
        <TableBody>
          <TableRow className="totalProduct">
            <TableCell>{t("titre.type")}</TableCell>
            <TableCell align="right">
              <b>{displayRoomType()}</b>
            </TableCell>
          </TableRow>
          <TableRow className="totalProduct">
            <TableCell>{t("chambre")}</TableCell>
            <TableCell align="right">
              <b>{displayRoomsTitle()}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.gl")}</TableCell>
            <TableCell align="right">
              <b>{displayBigBed()}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.pl")}</TableCell>
            <TableCell align="right">
              <b>{displaySmallBed()}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.date")}</TableCell>
            <TableCell align="right">
              <b>{search.startDate}</b> {t("titre.au")} <b>{search.endDate}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.pax")}</TableCell>
            <TableCell align="right">
              <div className="inputs-detail">
                <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                  <InputLabel
                    id="adult-label"
                    sx={{
                      color: "#303443!important",
                      fontWeight: "600",
                    }}
                  >
                    {t("titre.adulte")}
                  </InputLabel>
                  <Select
                    labelId="adult-label"
                    id="adult"
                    value={formik.values.adults}
                    label={t("titre.adulte")}
                    MenuProps={MenuProps}
                    onChange={(e) => handlePaxChange("adults", e.target.value)}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        border: 2,
                      },
                      ".MuiInputLabel-root": {
                        color: "red!important",
                      },
                      ".MuiOutlinedInput-input": {
                        color: "#303443!important",
                      },
                    }}
                  >
                    {Array.from({ length: 20 }).map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                  <InputLabel
                    id="kid-label"
                    sx={{
                      color: "#303443!important",
                      fontWeight: "600",
                    }}
                  >
                    {t("titre.enfant")}
                  </InputLabel>
                  <Select
                    labelId="kid-label"
                    id="kids"
                    value={formik.values.kids}
                    label={t("titre.enfant")}
                    onChange={(e) => handlePaxChange("kids", e.target.value)}
                    MenuProps={MenuProps}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        border: 2,
                      },
                      ".MuiInputLabel-root": {
                        color: "red!important",
                      },
                      ".MuiOutlinedInput-input": {
                        color: "#303443!important",
                      },
                    }}
                  >
                    {Array.from({ length: 20 }).map((_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.breakfast")}</TableCell>
            <TableCell align="right">
              Ar <b>{displayBreakfastPrice()}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.nuitée")}</TableCell>
            <TableCell align="right">
              Ar <b>{displayPrice()}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("titre.navette")}</TableCell>
            <TableCell align="right">
              Ar <b>{formik.values.navette.data.length * navettePrice}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} className="border-b-0">
              <ReservationSum formik={formik} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}
