import InputErrorMessage from "@/components/Contact/ContactForm/InputErrorMessage";
import { useReservationForm } from "@/hooks/reservation";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function ReservationInputs() {
  const { t } = useTranslation();
  const { formik } = useReservationForm();

  return (
    <div className="row align-items-center">
      <div className="col col-lg-6 col-12">
        <div className="form-field">
          <input
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            type="text"
            onChange={formik.handleChange}
            placeholder={t("formulaire.prenom")}
            className={
              formik.errors.firstName && formik.touched.firstName
                ? "invalid-form"
                : ""
            }
          />
          <InputErrorMessage formik={formik} name="firstName" />
        </div>
      </div>
      <div className="col col-lg-6 col-12">
        <div className="form-field">
          <input
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            type="text"
            onChange={formik.handleChange}
            placeholder={t("formulaire.nom")}
            className={
              formik.errors.lastName && formik.touched.lastName
                ? "invalid-form"
                : ""
            }
          />
          <InputErrorMessage formik={formik} name="lastName" />
        </div>
      </div>
      <div className="col col-lg-6 col-12">
        <div className="form-field">
          <input
            id="email"
            name="email"
            value={formik.values.email}
            type="email"
            onChange={formik.handleChange}
            placeholder={t("formulaire.email")}
            className={
              formik.errors.firstName && formik.touched.firstName
                ? "invalid-form"
                : ""
            }
          />
          <InputErrorMessage formik={formik} name="email" />
        </div>
      </div>
      <div className="col col-lg-6 col-12">
        <div className="form-field">
          <div className="d-flex items-center">
            <PhoneInput
              country={"mg"}
              value={formik.values.phoneNumber}
              placeholder={t("formulaire.telephone")}
              inputClass={
                formik.errors.firstName && formik.touched.firstName
                  ? "invalid-form"
                  : "input-tel"
              }
              autoFormat={false}
              onChange={(value) => formik.setFieldValue("phoneNumber", value)}
            />
          </div>
          <InputErrorMessage formik={formik} name="phoneNumber" />
        </div>
      </div>
    </div>
  );
}
