import { getAvailableRooms, getChambreDispoFilter } from "@/services/Chambre";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export function useAvailability() {
  const [chambres, setChambres] = useState([]);
  const [search, setSearch] = useState({});
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [noRoomsFound, setNoRoomsFound] = useState(false);

  useEffect(() => {
    if (location.state?.search) {
      if (
        location.state.search.startDate &&
        location.state.search.endDate &&
        location.state.search.hotelType
      ) {
        setSearch({
          startDate: location.state.search.startDate,
          endDate: location.state.search.endDate,
          adulte: 1,
          enfant: 0,
          hotelType: "Hôtel avec petit-déjeuner",
        });

        setLoading(true);
        setNoRoomsFound(false);
        getAvailableRooms({
          startDate: moment(
            location.state.search.startDate,
            "DD/MM/YYYY"
          ).toDate(),
          endDate: moment(location.state.search.endDate, "DD/MM/YYYY").toDate(),
          roomType: t(location.state.search.hotelType, {
            lng: "fr",
          }),
        })
          .then((rooms) => {
            if (rooms.length === 0) {
              setNoRoomsFound(true);
            }
            setChambres(rooms);
          })
          .catch(() => {
            setChambres([]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [location.state]);

  // useEffect(() => {
  //   let filter = location.state ? location.state.search : null;
  //   const today = moment().format("DD-MM-YYYY");
  //   const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
  //   if (!filter) {
  //     filter = {
  //       startDate: today,
  //       endDate: tomorrow,
  //       adulte: 1,
  //       enfant: 0,
  //       hotelType: "Hôtel avec petit-déjeuner",
  //     };
  //   }

  //   setSearch(filter);
  //   const fetchData = async () => {
  //     const data = await getChambreDispoFilter(
  //       filter.startDate,
  //       filter.endDate,
  //       filter.adulte,
  //       filter.enfant,
  //       t(filter.hotelType, {
  //         lng: "fr",
  //       })
  //     );
  //     setChambres(data);
  //   };
  //   fetchData();
  // }, [location.state, t]);

  async function searchAvailableRooms({
    startDate,
    endDate,
    adulte,
    enfant,
    hotelType,
  }) {
    const data = await getChambreDispoFilter(
      startDate,
      endDate,
      adulte,
      enfant,
      t(hotelType, {
        lng: "fr",
      })
    );
    return data;
  }

  return {
    searchAvailableRooms,
    search,
    chambres,
    setChambres,
    loading,
    noRoomsFound,
  };
}
