export const services = (t) => [
  {
    key: t("keyService.petitDej"),
    title: t("services.petitDej"),
  },
  {
    key: t("keyService.parking"),
    title: t("services.parking"),
  },
  {
    key: t("keyService.location"),
    title: t("keyService.location"),
    href: "/" + t("url.location"),
  },
];

export const partners = [
  {
    link: "https://www.facebook.com/valihahoteltana",
    name: "Facebook",
  },
  {
    link: "https://www.google.com/travel/hotels/Soarano%2C%20Tananarive/entity/CgoImsjGq5-H9uczEAE?q=valiha%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4703207%2C4718358%2C4723331%2C4757164%2C4786958%2C4790928%2C4794648%2C4814050%2C4816977%2C4828448%2C4831357%2C4832679%2C4835846&hl=fr-MG&gl=mg&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAJGAESBwjmDxAJGAIYATICEAAqCQoFOgNNR0EaAA&rp=EJrIxqufh_bnMxDBvtDiu7705uYBOAJAAEgCogETU29hcmFubywgVGFuYW5hcml2ZcABAw&ap=aAE&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwjI2pvQtN_5AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls",
    name: "Google",
  },
  {
    link: "#",
    name: "Airbnb",
  },
  {
    link: "https://www.booking.com/hotel/mg/valiha-antananarivo1.fr.html",
    name: "Booking.com",
  },
  {
    link: "https://www.tripadvisor.fr/Hotel_Review-g293809-d23970888-Reviews-Valiha_Hotel_Antananarivo_Antanimena-Antananarivo_Antananarivo_Province.html",
    name: "Page tripadvisor",
  },
];
