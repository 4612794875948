import { useEffect } from "react";

export default function ScrollTo({ top, children, behavior = "smooth" }) {
  useEffect(() => {
    window.scrollTo({
      behavior,
      top,
    });
  }, [behavior, top]);

  return <>{children}</>;
}
