import { useEffect, useState } from "react";
import { BlurhashCanvas } from "react-blurhash";

export default function LazyLoadImage({
  src,
  hash,
  width,
  height,
  cls = "",
  alt = "",
}) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src]);

  return (
    <div>
      {!imageLoaded && (
        <BlurhashCanvas hash={hash} width={width} height={height} punch={1} />
      )}
      {imageLoaded && <img src={src} alt={alt} className={cls} />}
    </div>
  );
}
