import InputErrorMessage from "@/components/Contact/ContactForm/InputErrorMessage";
import { useSearchReservation } from "@/hooks/reservation";
import { hasErrorsAndTouchedAtIndex, setToStartOfDay } from "@/utils";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

export default function NavetteAirport({ index, navette, formik }) {
  const { t } = useTranslation();
  const { search } = useSearchReservation();

  const min = setToStartOfDay(moment(search.startDate, "DD/MM/YYYY")).toDate();
  const max = moment(search.endDate, "DD/MM/YYYY").endOf("day").toDate();

  const handleDateChange = (date, _e) => {
    const value = {
      ...navette,
      dateArrival: date,
    };
    const updatedNavettes = [...formik.values.navette.data];
    updatedNavettes[index] = value;
    formik.setFieldValue(`navette.data`, updatedNavettes);
  };

  return (
    <>
      <div className="col-xs-12 col-md-12 col-lg-12">
        <div className="row">
          <div className="d-flex flex-column form-field col-lg-6 col-xs-12 col-md-6 col-lg-6">
            <label htmlFor="dateArrival">
              {t("formulaire.dateAirportArrival")}
            </label>
            <ReactDatePicker
              showIcon
              selected={navette.dateArrival}
              dateFormat="dd/MM/yyyy"
              minDate={min}
              maxDate={max}
              onChange={handleDateChange}
              className={`form-select ${
                hasErrorsAndTouchedAtIndex(
                  formik,
                  index,
                  "navette",
                  "dateArrival"
                )
                  ? "invalid-form"
                  : ""
              }`}
            >
              
            </ReactDatePicker>
            <InputErrorMessage
              formik={formik}
              name={`navette.data.${index}.dateArrival`}
            />
          </div>
          <div className="d-flex flex-column form-field col-xs-12 col-md-6 col-lg-6">
            <label
              htmlFor={`navette.data.${index}.timeArrival`}
            >
              {t("formulaire.heure")}
            </label>
            <input
              type="time"
              id={`navette.data.${index}.timeArrival`}
              name={`navette.data.${index}.timeArrival`}
              value={navette.timeArrival}
              onChange={formik.handleChange}
              className={`form-select ${
                hasErrorsAndTouchedAtIndex(
                  formik,
                  index,
                  "navette",
                  "timeArrival"
                )
                  ? "invalid-form"
                  : ""
              }`}
            />
            <InputErrorMessage
              formik={formik}
              name={`navette.data.${index}.timeArrival`}
            />
          </div>
        </div>
      </div>
    </>
  );
}
