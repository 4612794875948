import { CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingButton({ loading, text, onClick }) {
  return (
    <button
      type="submit"
      className="theme-btn d-flex justify-content-center align-item-center"
      disabled={loading}
      onClick={onClick}
      style={{
        opacity: loading ? 0.8 : 1,
      }}
    >
      {loading ? (
        <CircularProgress color="inherit" size={16} className="mt-0" />
      ) : (
        <span
          style={{
            opacity: loading ? 0.4 : 1,
          }}
        >
          {text}
        </span>
      )}
    </button>
  );
}
