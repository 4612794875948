import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchInformations } from "@/services/informations";

const InformationsContext = createContext();
const defaultInformations = [
  {
    title: "Prix petit déjeuner",
    key: "breakfastPrice",
    value: "25000",
  },
  {
    title: "Prix navette",
    key: "navettePrice",
    value: "100000",
  },
];

export const useInformations = (...keys) => {
  const context = useContext(InformationsContext);

  if (!context) {
    throw new Error(
      "useInformationsContext must be used within an InformationsProvider"
    );
  }

  const informations = context.informations;

  if (keys.length === 0) {
    return informations;
  }

  return keys.map((key) => {
    for (let info of informations) {
      if (info.key === key) {
        return info;
      }
    }
    return null;
  });
};

export const InformationsProvider = ({ children }) => {
  const [informations, setInformations] = useState([...defaultInformations]);

  useEffect(() => {
    fetchInformations()
      .then((data) => {
        setInformations(data);
      })
      .catch(() => {
        setInformations([...defaultInformations]);
      });
  }, []);

  return (
    <InformationsContext.Provider value={{ informations }}>
      {children}
    </InformationsContext.Provider>
  );
};
