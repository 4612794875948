import api from "@/utils/axios";

export const fetchInformations = async () => {
  const response = await api.get("/informations");
  return response.data;
};

export const updateInformations = async (updatedInformation) => {
  const response = await api.put("/informations", updatedInformation);
  return response.data;
};
