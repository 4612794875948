import ScrollTo from "@/components/ScrollTo";
import SearchSection from "@/components/SearchSection";
import './index.css';

const ReservationPage = () => {
	return (
		<ScrollTo top={0}>
			<div className="reservation-search-section">
				<SearchSection />
			</div>
		</ScrollTo>
	);
};

export default ReservationPage;
