import moment from "moment";

export function changeDateFormat(date, actualFormat, format) {
  return moment(moment(date, actualFormat).format(format), format).toDate();
}

export function castDateToString(date, format = "YYYY-MM-DD") {
  return moment(date).format(format);
}

export function getImageUrl(image) {
  return `${process.env.REACT_APP_BACK_URL}/uploads/${image}`;
}

export const hasErrorsAndTouchedAtIndex = (formik, index, field, name) => {
  return (
    formik?.errors[field]?.data?.[index]?.[name] &&
    formik?.touched[field]?.data?.[index]?.[name]
  ) || false;
};

export const getNestedProperty = (object, keys) => {
  return keys.reduce(
    (obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined),
    object
  );
};


export function setToStartOfDay(inputMoment) {
  return inputMoment
    .set("hour", 0)
    .set("minutes", 0)
    .set("seconds", 0)
    .set("milliseconds", 0);
}
