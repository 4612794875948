import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: [],
};

export const roomSlice = createSlice({
	name: "room",
	initialState,
	reducers: {
		setRoom: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setRoom } = roomSlice.actions;

export default roomSlice.reducer;
