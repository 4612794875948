import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: [],
};

export const hotelTypeSlice = createSlice({
	name: "hotelType",
	initialState,
	reducers: {
		setHotelType: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setHotelType } = hotelTypeSlice.actions;

export default hotelTypeSlice.reducer;
