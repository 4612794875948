import { useState } from "react";
import { FaXmark } from "react-icons/fa6";
import MenuList from "./MenuList";
import "./style.css";

const MobileMenu = () => {
  const [menuActive, setMenuState] = useState(false);
  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <FaXmark size={24} />
          </div>
        </div>
        <MenuList setMenuState={setMenuState} />
      </div>
      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn rounded-1" >
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
