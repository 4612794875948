import { availabilityValidationSchema } from "@/utils/validators/availability";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useSearchAvailability = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = new Date();
  const formik = useFormik({
    initialValues: {
      hotelType: "liste.type",
      startDate: today,
      endDate: today
    },
    validationSchema: availabilityValidationSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    navigate(`/${t("url.hebergement_dispo")}`, {
      state: {
        search: {
          startDate: moment(values.startDate).format("DD/MM/YYYY"),
          endDate: moment(values.endDate).format("DD/MM/YYYY"),
          hotelType: values.hotelType
        },
      },
    });
  };

  return {
    formik
  };
};
