import { useInformations } from "@/hooks/informations";
import { useSearchReservation } from "@/hooks/reservation";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export default function ReservationSum({ formik }) {
  const { t } = useTranslation();
  const { rooms, diffDay } = useSearchReservation();
  const [breakfastInfos, navetteInfos] = useInformations(
    "breakfastPrice",
    "navettePrice"
  );
  const [breakfastPrice, navettePrice] = [
    parseInt(breakfastInfos.value),
    parseInt(navetteInfos.value),
  ];

  const getTotalPrice = () => {
    const price = rooms.reduce((prev, acc) => acc.prix + prev, 0);
    return (
      price * diffDay +
      formik.values.navette.data.length * navettePrice +
      formik.values.breakfast * breakfastPrice * diffDay
    );
  };

  return (
    <>
      <div className="office-info">
        <Grid className="cartStatus">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid className="cartTotals">
                <div className="d-flex justify-content-between">
                  <h5>{t("titre.total")}</h5>
                  <h5>
                    Ar <b>{getTotalPrice()}</b>{" "}
                  </h5>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
