import { useTranslation } from "react-i18next";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { FaEnvelope, FaLocationDot, FaPhone } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";

const HeaderTopbar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigation = useNavigate();

  const onChange = (value) => {
    const pathname = location.pathname.replace(i18n.language, value);
    i18n.changeLanguage(value, () => {
      navigation(pathname, {
        state: location.state,
        replace: true,
      });
    });
  };

  return (
    <div className="topbar">
      <div className="container-fluid px-lg-5">
        <div className="row">
          <div className="col col-lg-9 col-md-10 col-sm-12 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <FaEnvelope size={16} /> contact@valihahotel.com
                </li>
                <li>
                  <FaPhone size={16} /> +261 38 98 368 02
                </li>
                <li>
                  <FaLocationDot size={16} /> {t("footer.contact.adresse")}
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-lg-3 col-md-2 col-sm-12 col-12">
            <div className="contact-info">
              <ul>
                <li className="language d-flex align-items-center">
                  <img
                    src={`/flagIcon/${i18n.resolvedLanguage}.png`}
                    alt="flag"
                    width="100%"
                    height="100%"
                    className="flag"
                  />
                  <select
                    id="lang"
                    name="language"
                    value={i18n.language}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  >
                    <option value="fr">{t("langue.francais")}</option>
                    <option value="en">{t("langue.anglais")}</option>
                    <option value="zh">{t("langue.chinois")}</option>
                  </select>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/valihahoteltana"
                    rel="noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.google.com/travel/hotels/Soarano%2C%20Tananarive/entity/CgoImsjGq5-H9uczEAE?q=valiha%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4703207%2C4718358%2C4723331%2C4757164%2C4786958%2C4790928%2C4794648%2C4814050%2C4816977%2C4828448%2C4831357%2C4832679%2C4835846&hl=fr-MG&gl=mg&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAJGAESBwjmDxAJGAIYATICEAAqCQoFOgNNR0EaAA&rp=EJrIxqufh_bnMxDBvtDiu7705uYBOAJAAEgCogETU29hcmFubywgVGFuYW5hcml2ZcABAw&ap=aAE&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwjI2pvQtN_5AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls"
                    rel="noreferrer"
                  >
                    <FaGoogle />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
