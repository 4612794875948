import Logo from "@/images/logo.png";
import { FaGoogle } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";

export default function FooterLinks() {
  return (
    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
      <div className="widget about-widget d-flex flex-wrap justify-content-around w-50">
        <div className="logo widget-title">
          <img src={Logo} alt="footer" width="100%" height="100%" />
        </div>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/valihahoteltana"
              rel="noreferrer"
              className="d-flex align-items-center justify-content-center"
            >
              <FaFacebookF size={16} />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.google.com/travel/hotels/Soarano%2C%20Tananarive/entity/CgoImsjGq5-H9uczEAE?q=valiha%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4703207%2C4718358%2C4723331%2C4757164%2C4786958%2C4790928%2C4794648%2C4814050%2C4816977%2C4828448%2C4831357%2C4832679%2C4835846&hl=fr-MG&gl=mg&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAJGAESBwjmDxAJGAIYATICEAAqCQoFOgNNR0EaAA&rp=EJrIxqufh_bnMxDBvtDiu7705uYBOAJAAEgCogETU29hcmFubywgVGFuYW5hcml2ZcABAw&ap=aAE&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwjI2pvQtN_5AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls"
              rel="noreferrer"
              className="d-flex align-items-center justify-content-center"
            >
              <FaGoogle size={16} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
