import { hotelTypes } from "@/constants";
import { useSearchAvailability } from "@/hooks/search-availability";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { FaHouseUser } from "react-icons/fa";
import {} from "moment";
import { useEffect } from "react";
import searchStyles from "./searchSection.module.css";

const SearchSection = (props) => {
  const { formik } = useSearchAvailability();
  let now = new Date(Date());
  const { t } = useTranslation();
  const [typeHotel, setTypeHotel] = useState(false);

  const onSelectHotelType = (hotelType) => {
    formik.setFieldValue("hotelType", hotelType);

    if (window.localStorage.getItem("choisirHotelType") !== "auto") {
      const typehotel = document.getElementById("typehotel");
      typehotel.click();
    }

    const startDate = document.getElementById("startDate");
    startDate.click();
  };

  function calculDate(date) {
    const temp = new Date(date);
    return temp.setDate(temp.getDate() + 1);
  }

  useEffect(() => {
    const temp = new Date(formik.values.startDate);
    temp.setDate(temp.getDate() + 1);
    formik.setFieldValue("endDate", temp);
  }, [formik.values.startDate]);

  return (
    <div
      className={`wpo-select-section ${props.svClass}  ${searchStyles.search}`}
      style={{
        top: props.top ?? 70,
        marginTop: -180,
      }}
    >
      <div className="row container justify-content-center mx-auto">
        <div className="form-container">
          <form noValidate onSubmit={formik.handleSubmit} className="shadow-sm">
            <div className="select-sub d-flex align-items-center justify-content-center">
              <div className="form-group tourist-group bordered">
                <div className="tourist-group-wrap">
                  <div
                    className="tourist-inner"
                    onClick={() => setTypeHotel(!typeHotel)}
                  >
                    <i className="">
                      <FaHouseUser className="icon" />
                    </i>
                    <ul>
                      <li className="mx-1" id="type">
                        {t(formik.values.hotelType)}
                      </li>
                    </ul>
                    <i
                      id="typehotel"
                      className={`ti-angle-down ${typeHotel ? "rotate" : ""}`}
                    ></i>
                  </div>
                </div>
                {formik.touched.hotelType && formik.errors.hotelType && (
                  <div
                    className="erreur tourist-dropdown typeErreur active"
                    id="typeError"
                  >
                    <div className="tourist-item error d-flex justify-content-center">
                      <p>{t("section.type")}</p>
                    </div>
                  </div>
                )}
                <div
                  id="myTypeHotel"
                  className={`tourist-dropdown ${typeHotel ? "active" : ""}`}
                >
                  {hotelTypes.map((hotelType) => {
                    return (
                      <div
                        key={hotelType}
                        className="tourist-item justify-content-around align-items-center"
                      >
                        <button
                          type="button"
                          className={searchStyles.menu}
                          onClick={() => onSelectHotelType(hotelType)}
                        >
                          {t(hotelType)}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="select-sub d-flex align-items-center justify-content-center"
              id="dateStart"
            >
              <div className="input-group date bordered">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={formik.values.startDate}
                  minDate={now}
                  id="startDate"
                  onChange={(date) => {
                    formik.setFieldValue("startDate", date);
                    const endDate = document.getElementById("endDate");
                    endDate.click();
                  }}
                  className="relative position-relative"
                />
                <i className="fi flaticon-calendar"></i>
                {formik.touched.startDate && formik.errors.startDate && (
                  <div
                    className="erreur widthDate tourist-dropdown active"
                    id="startDateError"
                  >
                    <div className="errorDate tourist-item error d-flex justify-content-center">
                      <p>{t("section.date")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="select-sub d-flex align-items-center justify-content-center">
              <div className="input-group date">
                <DatePicker
                  selected={formik.values.endDate}
                  id="endDate"
                  dateFormat="dd/MM/yyyy"
                  minDate={calculDate(formik.values.startDate)}
                  onChange={(date) => {
                    formik.setFieldValue("endDate", date);
                  }}
                />
                <i className="fi flaticon-calendar"></i>
                {formik.touched.endDate && formik.errors.startDate && (
                  <div
                    className="erreur widthDate tourist-dropdown active"
                    id="endDateError"
                  >
                    <div className="errorDate tourist-item error d-flex justify-content-center align-items-center">
                      <p>{t("section.date")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="select-sub" id="afficheErreur">
              <button
                type="submit"
                className="theme-btn d-flex align-items-center justify-content-center"
              >
                {t("bouton.bDispo")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
