import { setToStartOfDay } from "@/utils";
import { getReservationValidationSchema } from "@/utils/validators/reservation";
import { useFormik } from "formik";
import moment from "moment";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAvailability } from "./availability";

export function useSearchReservation() {
  const location = useLocation();
  const { rooms, search, adulte, enfant } = location.state;

  const diffDay = moment(search.endDate, "DD/MM/YYYY").diff(
    moment(search.startDate, "DD/MM/YYYY"),
    "days"
  );

  return {
    rooms,
    search: {
      startDate: search.startDate,
      endDate: search.endDate,
      adulte,
      enfant,
    },
    diffDay,
  };
}

export const ReservationFormContext = createContext();

export function ReservationFormProvider({ children }) {
  const { rooms, search, diffDay } = useSearchReservation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const startDate = setToStartOfDay(
    moment(search.startDate, "DD/MM/YYYY")
  ).toDate();
  const endDate = moment(search.endDate, "DD/MM/YYYY").endOf("day");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "male",
      email: "",
      phoneNumber: "",
      adults: search.adulte,
      kids: search.enfant,
      breakfast: 0,
      navette: {
        checked: false,
        data: [],
      },
      parking: false,
      note: "",
    },
    validationSchema: getReservationValidationSchema(startDate, endDate),
    onSubmit: async (values) => {
      navigate(`/${t("url.formulaire")}/confirmation`, {
        state: {
          values,
          rooms,
          search,
          diffDay,
        },
      });
    },
  });

  const handlePaxChange = (field, value) => {
    const total_adult = rooms.reduce(
      (acc, curr) => acc + curr.idCategorie.adulte,
      0
    );
    const total_kid = rooms.reduce(
      (acc, curr) => acc + curr.idCategorie.enfant,
      0
    );
    const adults = formik.values.adults;
    const kids = formik.values.kids;
    let total = 0;
    let prevValue;

    if (field === "adults") {
      total += parseInt(value) + parseInt(kids);
      prevValue = parseInt(adults);
    } else {
      total += parseInt(value) + parseInt(adults);
      prevValue = parseInt(kids);
    }

    if (total <= total_adult + total_kid) {
      formik.setFieldValue(field, value);
    } else {
      formik.setFieldValue(field, prevValue);
    }
  };

  return (
    <ReservationFormContext.Provider
      value={{
        formik,
        rooms,
        search,
        diffDay,
        handlePaxChange,
      }}
    >
      {children}
    </ReservationFormContext.Provider>
  );
}

export function useReservationForm() {
  const c = useContext(ReservationFormContext);
  if (!c) {
    console.log(
      "Error : Please wrap the near parent with ReservationFormProvider to access this hook"
    );
  }
  return c;
}

export default function useReservation() {
  const { rooms, search, diffDay } = useSearchReservation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const startDate = setToStartOfDay(
    moment(search.startDate, "DD/MM/YYYY")
  ).toDate();
  const endDate = moment(search.endDate, "DD/MM/YYYY").endOf("day");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      adults: search.adulte,
      kids: search.enfant,
      breakfast: {
        checked: false,
        data: [],
      },
      navette: {
        checked: false,
        data: [],
      },
      parking: false,
    },
    validationSchema: getReservationValidationSchema(startDate, endDate),
    onSubmit: async (values) => {
      navigate(`/${t("url.formulaire")}/confirmation`, {
        state: {
          values,
          rooms,
          search,
          diffDay,
        },
      });
    },
  });

  const handlePaxChange = (field, value) => {
    const total_adult = rooms.reduce(
      (acc, curr) => acc + curr.idCategorie.adulte,
      0
    );
    const total_kid = rooms.reduce(
      (acc, curr) => acc + curr.idCategorie.enfant,
      0
    );
    const adults = formik.values.adults;
    const kids = formik.values.kids;
    let total = 0;
    let prevValue;

    if (field === "adults") {
      total += parseInt(value) + parseInt(kids);
      prevValue = parseInt(adults);
    } else {
      total += parseInt(value) + parseInt(adults);
      prevValue = parseInt(kids);
    }

    if (total <= total_adult + total_kid) {
      formik.setFieldValue(field, value);
    } else {
      formik.setFieldValue(field, prevValue);
    }
  };

  return {
    formik,
    rooms,
    search,
    diffDay,
    handlePaxChange,
  };
}

export function useReservationConfirmation() {
  const location = useLocation();
  const { values, rooms, search, diffDay } = location.state;

  return {
    values,
    rooms,
    search,
    diffDay,
  };
}

export const useSelectRooms = () => {
  const { search } = useAvailability();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [process, setProcess] = useState(false);
  const formik = useFormik({
    initialValues: {
      adulte: 1,
      enfant: 0,
    },
  });

  const handlePaxChange = (field, value) => {
    const total_adult = selectedRooms.reduce(
      (acc, curr) => acc + curr.idCategorie.adulte,
      0
    );
    const total_kid = selectedRooms.reduce(
      (acc, curr) => acc + curr.idCategorie.enfant,
      0
    );
    const adults = formik.values.adulte;
    const kids = formik.values.enfant;
    let total = 0;

    if (field === "adulte") {
      total += parseInt(value) + parseInt(kids);
    } else {
      total += parseInt(value) + parseInt(adults);
    }

    const paxes = total_adult + total_kid;
    if (total <= paxes && paxes > 0) {
      formik.setFieldValue(field, parseInt(value));
      setProcess(true);
    } else {
      formik.setFieldValue(field, parseInt(value));
      setProcess(false);
    }
  };

  const chooseRoom = (selected) => {
    const exists = selectedRooms.some((v) => v._id === selected._id);
    if (exists || !process) {
      const rooms = selectedRooms.filter((room) => room._id !== selected._id);

      if (rooms.length === selectedRooms.length) {
        rooms.push(selected);
      }

      const total_adult = rooms.reduce(
        (acc, curr) => acc + curr.idCategorie.adulte,
        0
      );
      const total_kid = rooms.reduce(
        (acc, curr) => acc + curr.idCategorie.enfant,
        0
      );

      if (
        formik.values.adulte + formik.values.enfant <=
        total_adult + total_kid
      ) {
        setProcess(true);
      } else {
        setProcess(false);
      }

      setSelectedRooms(rooms);
    }
  };

  const isChosen = (room) => {
    return selectedRooms.some((item) => item._id === room._id);
  };

  return {
    search,
    chooseRoom,
    isChosen,
    process,
    selectedRooms,
    handlePaxChange,
    formik,
  };
};
