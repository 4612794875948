import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { useEffect } from "react";
import { useInformations } from "@/hooks/informations";

export default function BreakfastInputs({ index, formik, breakfast }) {
  const { t } = useTranslation();
  const [breakfastInfos] = useInformations("breakfastPrice");

  const breakfastPrice = parseInt(breakfastInfos.value);
  const [selectValue, setSelectValue] = useState(
    formik.values.adults + formik.values.kids
  );
  const prixParDatePetitDejeuner = formik.values.breakfast * breakfastPrice;
  const numberOfPerson = formik.values.adults + formik.values.kids;
  // const totalBreakfastPrice = prixParDatePetitDejeuner * diffDay;

  useEffect(() => {
    setSelectValue(formik.values.adults + formik.values.kids);
  }, [formik.values.adults, formik.values.kids]);

  useEffect(() => {
    formik.setFieldValue(`breakfast`, selectValue);
  }, [selectValue]);

  const handleDataChange = (value) => {
    setSelectValue(parseInt(value));
  };

  return (
    <>
      <div className="d-flex justify-content-between justify-content-lg-start mb-5 pb-3">
        <div
          style={{
            width: 200,
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="pax_number_label">
              {t("formulaire_dejeuner.pax")}
            </InputLabel>
            <Select
              labelId="pax_number_label"
              id="pax_number"
              label={t("formulaire_dejeuner.pax")}
              value={selectValue}
              onChange={(e) => handleDataChange(e.target.value)}
            >
              {Array.from(new Array(numberOfPerson)).map((_, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="ms-5 d-flex flex-column">
          <h5
            className="m-0 p-0"
            style={{
              position: "relative",
              top: -5,
            }}
          >
            Total{" "}
          </h5>
          <span className="h6 m-0 p-0">
            Ar {prixParDatePetitDejeuner}{" "}
            {t("formulaire_dejeuner.perNightPerGuest")}
          </span>
        </div>
      </div>
    </>
  );
}
