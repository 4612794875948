import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import ScrollBar from "@/components/ScrollBar";
import Logo from "@/images/logo2.png";
import { createContext } from "react";
// import LazyLoad from "react-lazyload";
import { Outlet } from "react-router-dom";
import BannerImage from "../LayoutPage/BannerImage";
import styles from "./index.module.css";

// Just for accessing the 'isFixed' value deeply without passing many props
export const LayoutContext = createContext();

const Layout = ({ isFixed = false, withBannerImage = true }) => {
  const showBannerImage = !isFixed && withBannerImage;

  return (
    <div id="scrool">
      <LayoutContext.Provider
        value={{
          isFixed,
        }}
      >
        <Navbar
          topbarBlock={"wpo-header-style-2"}
          hclass={"wpo-header-style"}
          Logo={Logo}
        />
      </LayoutContext.Provider>
      {showBannerImage ? <BannerImage /> : null}
      <div
        className={`${
          !isFixed ? styles.outletContainer : styles.outletContainerFixed
        }`}
      >
        <Outlet />
      </div>
      <Footer />
      <ScrollBar />
    </div>
  );
};

export default Layout;
