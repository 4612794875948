import { navetteDefaultValue } from "@/constants";
import { useReservationForm, useSearchReservation } from "@/hooks/reservation";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoIosAdd } from "react-icons/io";
import { If, Then } from "react-if";

import CheckboxParking from "./CheckBoxParking";
import Navette from "./Navette";
import NavetteItem from "./NavetteItem";
import { useContext } from "react";
import "./index.css";
import { ErrorScrollContext } from "@/utils/error-scroll";
import { useInformations } from "@/hooks/informations";

const FormulaireForm = () => {
  const { t } = useTranslation();
  const { search } = useSearchReservation();
  const { formik } = useReservationForm();
  const { shuttleErrorView } = useContext(ErrorScrollContext);
  const [navetteInfos] = useInformations("navettePrice");
  const navettePrice = parseInt(navetteInfos.value);

  const handleAddNavette = () => {
    const fieldValue = formik.values.navette;
    const updatedNavette = [
      ...fieldValue.data,
      navetteDefaultValue(moment(search.startDate, "DD/MM/YYYY").toDate()),
    ];
    formik.setFieldValue("navette.data", updatedNavette);
  };

  return (
    <>
      <div className="mt-4 d-flex align-items-center">
        <h4 className="mb-1">{t("titre.formulaire")} </h4>{" "}
        <span className="ms-3">( Ar {navettePrice} )</span>
      </div>
      <div className="d-flex align-items-center" ref={shuttleErrorView}>
        <Navette formik={formik} />
        <CheckboxParking formik={formik} />
      </div>
      <If condition={formik.values.navette.data.length > 0}>
        <Then>
          <div className="border border-dark-subtle form_navete mb-4">
            {formik.values.navette.data.map((navette, index) => (
              <NavetteItem
                key={index}
                index={index}
                formik={formik}
                navette={navette}
              />
            ))}
            <div
              className="form_navete e-mouse-pointer cursor-pointer d-inline"
              onClick={handleAddNavette}
            >
              <IoIosAdd size={"2rem"} />
              <span>{t("titre.addShuttle")}</span>
            </div>
          </div>
        </Then>
      </If>

      {/* Note (remark) */}
      <div className="note mb-5">
        <h4>
          <label htmlFor="note">{t("formulaire.note")}</label>
        </h4>
        <div
          style={{
            width: "60%",
          }}
        >
          <textarea
            id="note"
            rows={0}
            cols={50}
            value={formik.values.note}
            onChange={formik.handleChange}
            placeholder={t("formulaire.notePlaceholder")}
          />
          <span className="text-secondary">
            * {t("formulaire.noteDetails")}
          </span>
        </div>
      </div>
    </>
  );
};

export default FormulaireForm;
