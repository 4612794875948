import InputErrorMessage from "@/components/Contact/ContactForm/InputErrorMessage";

const { useTranslation } = require("react-i18next");

export default function CheckboxBreakfast({ formik, handleChange }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex justify-content-start">
        <div className=" d-flex align-items-center">
          <label className="form-check-label" htmlFor="parking">
            {t("titre.breakfast")} :{" "}
          </label>
        </div>
        <div className=" d-flex align-items-center ms-3">
          <input
            id="breakfast-checkbox"
            name="breakfast.checked"
            type="checkbox"
            checked={formik.values.breakfast.checked}
            onChange={handleChange}
          />
          <label className="form-check-label mx-1" htmlFor="breakfast-checkbox">
            {t("formulaire.oui")}
          </label>
        </div>
      </div>
      <InputErrorMessage formik={formik} name="breakfast.checked" />
    </>
  );
}
