import { ThreeDots } from "react-loader-spinner";
import './index.css';

export default function SuspenseLoader() {
	return (
		<div className='load-spinner'>
			<ThreeDots
				height='80'
				width='80'
				radius='9'
				color='rgb(239, 82, 63)'
				ariaLabel='three-dots-loading'
				visible={true}
			/>
		</div>
	);
}
