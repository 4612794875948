import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ServiceItem({ service }) {
  const { t } = useTranslation();

  const href = service.href ?? `/${t("url.service")}/` + service.key;

  return (
    <li key={service.key}>
      <Link
        id="service"
        to={href}
        onClick={() => {
          window.scrollTo(10, 0);
        }}
      >
        {service.title}
      </Link>
    </li>
  );
}
