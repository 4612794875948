import InputErrorMessage from "@/components/Contact/ContactForm/InputErrorMessage";
import NavetteAirport from "@/components/Reservation/ReservationForm/NavetteAirport";
import { hasErrorsAndTouchedAtIndex } from "@/utils";
import { useTranslation } from "react-i18next";

export default function NavetteForm({ index, navette, formik }) {
  const { t } = useTranslation();

  const handleSelection = (e) => {
    formik.handleChange(e);
    let value = e.target.value;
    if (value === "autre") {
      value = "";
    }
    formik.setFieldValue(`navette.data.${index}.direction`, value);
  };

  return (
    <div className="row mt-4">
      <div className="col-xs-12 col-md-6 col-lg-6">
        <div className="form-field">
          <input
            id={`navette.data.${index}.name`}
            name={`navette.data.${index}.name`}
            type="text"
            value={navette.name}
            onChange={formik.handleChange}
            placeholder={t("formulaire.navetteName")}
            className={`${
              hasErrorsAndTouchedAtIndex(formik, index, "navette", "name")
                ? "invalid-form"
                : ""
            }`}
          />
          <InputErrorMessage
            formik={formik}
            name={`navette.data.${index}.name`}
          />
        </div>
      </div>
      <div className="col-xs-12 col-md-6 col-lg-6">
        <div className="form-field">
          <input
            type="text"
            id={`navette.data.${index}.flightNumber`}
            name={`navette.data.${index}.flightNumber`}
            value={navette.flightNumber}
            onChange={formik.handleChange}
            placeholder={t("formulaire.numeroVol")}
            className={`${
              hasErrorsAndTouchedAtIndex(
                formik,
                index,
                "navette",
                "flightNumber"
              )
                ? "invalid-form"
                : ""
            }`}
          />
          <InputErrorMessage
            formik={formik}
            name={`navette.data.${index}.flightNumber`}
          />
        </div>
      </div>
      <div className="col-xs-12 col-md-6 col-lg-6">
        <div className="form-field">
          <select
            name={`navette.data.${index}.selection`}
            value={navette.selection}
            onChange={handleSelection}
            className={`form-select ${
              hasErrorsAndTouchedAtIndex(formik, index, "navette", "selection")
                ? "invalid-form"
                : ""
            }`}
          >
            <option hidden>{t("formulaire.lieu_navette")}</option>
            <option value={"airportToHotel"}>
              {t("formulaire.airportToHotel")}
            </option>
            <option value={"hotelToAirport"}>
              {t("formulaire.hotelToAirport")}
            </option>
            <option value={"autre"}>{t("formulaire.autreNavette")}</option>
          </select>
          <InputErrorMessage
            formik={formik}
            name={`navette.data.${index}.selection`}
          />
        </div>
      </div>

      <div
        className={`col-xs-12 col-md-6 col-lg-6 ${
          formik.values.navette.data?.[index]?.selection !== "autre"
            ? "invisible"
            : "visible"
        }`}
      >
        <div className="form-field">
          <input
            type="text"
            name={`navette.data.${index}.direction`}
            value={navette.direction}
            onChange={formik.handleChange}
            placeholder={t("formulaire.lieuNavetteName")}
            className={`form-select ${
              hasErrorsAndTouchedAtIndex(formik, index, "navette", "direction")
                ? "invalid-form"
                : ""
            }`}
          />
          <InputErrorMessage
            formik={formik}
            name={`navette.data.${index}.direction`}
          />
        </div>
      </div>

      <NavetteAirport index={index} navette={navette} formik={formik} />
    </div>
  );
}
