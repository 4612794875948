import { useReservationForm } from "@/hooks/reservation";
import { useTranslation } from "react-i18next";
import BreakfastInputItem from "./BreakfastInputItem";
import CheckboxBreakfast from "./CheckboxBreakfast";
import { If, Then } from "react-if";
import { useInformations } from "@/hooks/informations";

export default function BreakfastForm() {
  const { t } = useTranslation();
  const { formik } = useReservationForm();
  const [breakfastInfos] = useInformations("breakfastPrice");
  const breakfastPrice = parseInt(breakfastInfos.value);

  const handleChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      formik.setFieldValue(
        "breakfast",
        formik.values.adults + formik.values.kids
      );
    } else {
      formik.setFieldValue("breakfast", 0);
    }
    // formik.setFieldValue("breakfast.checked", checked);
  };

  return (
    <div className="col-12 mt-5">
      <div className="d-flex align-items-center">
        <h4 className="pb-1">{t("titre.breakfastReservation")}</h4>
        <span className="h6 ms-3"> ( Ar {breakfastPrice} / pax )</span>
      </div>
      <CheckboxBreakfast formik={formik} handleChange={handleChange} />
      <If condition={formik.values.breakfast > 0}>
        <Then>
          <div className="form_navete mb-5">
            <BreakfastInputItem
              // key={i}
              // index={i}
              formik={formik}
              breakfast={formik.values.breakfast}
            />
            {/* {formik.values.breakfast.data.map((breakfast, i) => (
              <BreakfastInputItem
                key={i}
                index={i}
                formik={formik}
                breakfast={breakfast}
              />
            ))} */}
          </div>
        </Then>
      </If>
    </div>
  );
}
