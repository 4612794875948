import i18next from "i18next";
import {
  FaBuilding,
  FaEye,
  FaFan,
  FaMap,
  FaShower,
  FaWifi,
} from "react-icons/fa";

export const drapeau = {
  en: "/flagIcon/en.png",
  fr: "/flagIcon/fr.png",
  zh: "/flagIcon/zh.png",
};

export const DATE_FORMAT = "DD/MM/YYYY";

export const typeServices = {
  petit_dejeuner: {
    title: i18next.t("services.petitDej"),
    images: [
      {
        key: 1,
        alt: "Petit-déjeuner au lit",
        src: "breakfast/p1.webp",
      },
      {
        key: 2,
        alt: "Des fruits de saison",
        src: "breakfast/p2.webp",
      },
      {
        key: 3,
        alt: "Notre viennoiserie du matin",
        src: "breakfast/p3.webp",
      },
      {
        key: 4,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p4.webp",
      },
      {
        key: 5,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p5.webp",
      },
      {
        key: 6,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p6.webp",
      },
      {
        key: 7,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p7.webp",
      },
      {
        key: 8,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p8.webp",
      },
      {
        key: 9,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p9.webp",
      },
      {
        key: 10,
        alt: "Notre pain aux raisins du matin",
        src: "breakfast/p10.webp",
      },
      {
        key: 11,
        alt: "Petit-déjeuner Valiha Hôtel",
        src: "breakfast/p11.webp",
      },
      {
        key: 12,
        alt: "Service petit-déjeuner",
        src: "breakfast/p12.webp",
      },
    ],
  },
  parking: {
    title: i18next.t("services.parking"),
    images: [
      {
        key: 12,
        alt: "Parking Valiha hôtel",
        src: "../../serviceFooter/parking/parking.webp",
      },
    ],
  },
  navette_aeroport: {
    title: i18next.t("services.navette"),
    images: [
      {
        key: 12,
        alt: "Navette valiha hôtel",
        src: "../../serviceFooter/navette/navette.webp",
      },
    ],
  },
};

export const languages = ["fr", "en", "zh"];

export const photos = [
  {
    key: 1,
    href: "galleries/1.webp",
    alt: "Chambre Deluxe Double Valiha Hôtel",
    src: "galleries/1.webp",
  },
  {
    key: 2,
    href: "galleries/2.webp",
    alt: "Chambre Deluxe Double Valiha Hôtel",
    src: "galleries/2.webp",
  },
  {
    key: 3,
    href: "galleries/3.webp",
    alt: "Chambre Deluxe Twin Valiha Hôtel",
    src: "galleries/3.webp",
  },
  {
    key: 4,
    href: "galleries/4.webp",
    alt: "Chambre Triple Valiha Hôtel",
    src: "galleries/4.webp",
  },
  {
    key: 5,
    href: "galleries/5.webp",
    alt: "Petit-déjeuner Valiha Hôtel",
    src: "galleries/5.webp",
  },
  {
    key: 6,
    href: "galleries/6.webp",
    alt: "Chambre Standard Valiha Hôtel",
    src: "galleries/6.webp",
  },
  {
    key: 7,
    href: "galleries/7.webp",
    alt: "Chambre Deluxe Double Valiha Hôtel",
    src: "galleries/7.webp",
  },
  {
    key: 8,
    href: "galleries/8.webp",
    alt: "Valiha Hôtel la nuit",
    src: "galleries/8.webp",
  },
  {
    key: 9,
    href: "galleries/9.webp",
    alt: "Valiha Hôtel",
    src: "galleries/9.webp",
  },
  {
    key: 10,
    href: "galleries/10.webp",
    alt: "Voulez-vous fêter quelque chose?",
    src: "galleries/10.webp",
  },
  {
    key: 11,
    href: "galleries/11.webp",
    alt: "Un coin tranquille pour prendre une tasse de thé ou lire un bon livre",
    src: "galleries/11.webp",
  },
  {
    key: 12,
    href: "galleries/12.webp",
    alt: "Chambre Standard Valiha Hôtel",
    src: "galleries/12.webp",
  },
  {
    key: 13,
    href: "galleries/13.webp",
    alt: "Une chambre bien aérée",
    src: "galleries/13.webp",
  },
  {
    key: 14,
    href: "galleries/14.webp",
    alt: "Un accueil chaleureux",
    src: "galleries/14.webp",
  },
  {
    key: 15,
    href: "galleries/15.webp",
    alt: "Un petit salon dans notre appartement",
    src: "galleries/15.webp",
  },
  {
    key: 16,
    href: "galleries/16.webp",
    alt: "Notre table de nuit en palissandre",
    src: "galleries/16.webp",
  },
  {
    key: 17,
    href: "galleries/17.webp",
    alt: "Chambre familiale Valiha Hôtel",
    src: "galleries/17.webp",
  },
  {
    key: 18,
    href: "galleries/18.webp",
    alt: "Notre petit accessoire de chambre",
    src: "galleries/18.webp",
  },
  {
    key: 19,
    href: "galleries/19.webp",
    alt: "Notre petit café du matin",
    src: "galleries/19.webp",
  },
  {
    key: 20,
    href: "galleries/20.webp",
    alt: "Une magnifique vue sur notre terrasse",
    src: "galleries/20.webp",
  },
  {
    key: 21,
    href: "galleries/22.webp",
    alt: "Valiha Hôtel le jour",
    src: "galleries/22.webp",
  },
];

export const galleryPhotoSwiper = [
  {
    image: "galleries/1.webp",
  },
  {
    image: "galleries/2.webp",
  },
  {
    image: "galleries/3.webp",
  },
  {
    image: "galleries/4.webp",
  },
  {
    image: "galleries/5.webp",
    children: true,
  },
];

export const tagsOfBlog = [
  {
    title: "blog.voyage",
    link: "https://www.google.com/search?q=agence+de+voyage+madagascar&oq=agence+de+voyage+madagascar&aqs=chrome..69i57j0i512l3j0i22i30l3j0i15i22i30l3.8081j0j9&sourceid=chrome&ie=UTF-8",
  },
  {
    title: "blog.restaurant",
    link: "https://www.google.com/search?q=restaurant+antananarivo+madagascar&sxsrf=ALiCzsZsv7m92BbeLZucwEIhwK9X0adplQ%3A1667366779097&ei=e_9hY_7LBZeM8gLc6JfgCg&ved=0ahUKEwi-9c-k4Y77AhUXhlwKHVz0BawQ4dUDCA8&uact=5&oq=restaurant+antananarivo+madagascar&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIGCAAQFhAeMgYIABAWEB46CAgAEKIEELADOgQIIxAnOgoIABCxAxCDARBDOgQIABBDOgcIABDJAxBDOgUIABCABDoLCC4QgAQQxwEQrwE6CAgAEIAEEMkDOggIABCABBDLAToICAAQFhAeEA86CAgAEBYQHhAKSgQIQRgASgQIRhgAUNwNWKupAmDOrQJoBnAAeAKAAb5XiAGgtgGSARAyLTExLjQuOC40LjIuOS0xmAEAoAEByAEDwAEB&sclient=gws-wiz-serp",
  },
  {
    title: "blog.tour",
    link: "https://www.google.com/search?q=destination+madagascar&sxsrf=ALiCzsbNlxlYcLxJ8eeSWwf992R_2AfW_Q%3A1667366852895&ei=xP9hY5OkNtyEhbIPkcKSoAE&oq=destination+&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAxgAMgQIABBDMgUILhCABDIFCAAQgAQyBQguEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEOgoIABBHENYEELADOg0IABBHENYEELADEMkDOgQIIxAnOgsIABCABBCxAxCDAToICAAQsQMQgwE6BQgAELEDOg4ILhCABBCxAxCDARDUAjoICAAQgAQQsQM6CgguEMcBEK8BEEM6CggAELEDEIMBEEM6EQguEIAEELEDEIMBEMcBENEDOgQILhBDOgkIIxAnEEYQ-QFKBAhBGABKBAhGGABQtxFYlx5gsy1oAXABeACAAc0CiAG8G5IBBjItMTAuMpgBAKABAcgBCMABAQ&sclient=gws-wiz-serp",
  },
  {
    title: "blog.chambre",
    link: "https://www.google.com/search?q=tour+de+madagascar&sxsrf=ALiCzsYMbshj2Vglgji6iCYvDUOL5grGDw%3A1667366977536&ei=QQBiY8aoII20gQa1z4PYBw&oq=tour+de+madagascar&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQARgAMgUIABCABDIFCAAQgAQyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yAggmMgIIJjoKCAAQRxDWBBCwAzoECCMQJzoECAAQQzoLCAAQgAQQsQMQgwE6DQguEMcBEK8BENQCEEM6BAguEEM6BwguENQCEEM6CgguELEDENQCEEM6CgguEMcBEK8BEEM6CwguEIAEEMcBEK8BOgoIABCABBCHAhAUOggILhCABBDUAjoFCC4QgAQ6CAgAEIAEEMsBOgcIABCABBAKSgQIQRgASgQIRhgAUKUJWMciYO4yaAFwAXgAgAHKAogBwSiSAQYyLTE1LjOYAQCgAQHIAQjAAQE&sclient=gws-wiz-serp",
  },
  {
    title: "blog.site",
    link: "https://www.google.com/search?q=site+touristique+madagascar&sxsrf=ALiCzsbCtflO5ljigtYsFOZSvy3oUgr80A%3A1667367205653&ei=JQFiY9zDJ4uF8gLwp6eQDg&oq=s&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQARgAMgQIIxAnMgQIIxAnMgQIIxAnMgQIABBDMgQIABBDMgQIABBDMgQIABBDMgsIABCABBCxAxCDATILCAAQgAQQsQMQgwEyCwgAEIAEELEDEIMBOgoIABBHENYEELADSgQIQRgASgQIRhgAUNcLWNcLYPEgaAFwAXgAgAGJA4gBiQOSAQMzLTGYAQCgAQHIAQjAAQE&sclient=gws-wiz-serp",
  },
  {
    title: "blog.guide",
    link: "https://www.google.com/search?q=guide+touristique+madagascar&sxsrf=ALiCzsbCtflO5ljigtYsFOZSvy3oUgr80A%3A1667367205653&ei=JQFiY9zDJ4uF8gLwp6eQDg&oq=guide+touristique+ma&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAxgAMgoIABCABBCHAhAUMgoIABCABBCHAhAUMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQ6CggAEEcQ1gQQsAM6BAgjECc6BggjECcQEzoQCC4QsQMQgwEQxwEQ0QMQQzoKCAAQsQMQgwEQQzoHCAAQsQMQQzoLCAAQgAQQsQMQgwE6CAgAEIAEELEDOgoILhDHARDRAxAnOgQILhBDOhAILhCABBCHAhDHARCvARAUOggILhCxAxCDAToOCC4QgAQQxwEQ0QMQ1AI6BQguEIAEOgcIABDJAxBDOgQIABBDOgcILhDUAhBDSgQIQRgASgQIRhgAUKsLWLxBYNJNaAJwAXgCgAGKEIgBujmSAQ0yLTIuMy43LTMuMC4xmAEAoAEByAEIwAEB&sclient=gws-wiz-serp",
  },
];

export const roomSettings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  centerMode: true,
  autoplaySpeed: 3500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const choices = [
  {
    key: 1,
    icons: <FaBuilding />,
    title: "choix.immeuble.titre",
    description: "choix.immeuble.description",
  },
  {
    key: 2,
    icons: <FaFan />,
    title: "choix.clim.titre",
    description: "choix.clim.description",
  },
  {
    key: 3,
    icons: <FaEye />,
    title: "choix.vue.titre",
    description: "choix.vue.description",
  },
  {
    key: 4,
    icons: <FaWifi />,
    title: "choix.connexion.titre",
    description: "choix.connexion.description",
  },
  {
    key: 5,
    icons: <FaShower />,
    title: "choix.eau.titre",
    description: "choix.eau.description",
  },
  {
    key: 6,
    icons: <FaMap />,
    title: "choix.carte.titre",
    description: "choix.carte.description",
  },
];

export const menus = (t) => [
  {
    id: 1,
    title: t("menu.accueil"),
    link: "/" + t("url.langue"),
  },
  {
    id: 2,
    title: t("menu.reservation"),
    link: "/" + t("url.reservation"),
  },
  {
    id: 3,
    title: t("menu.blog"),
    link: "/" + t("url.blog"),
  },
  {
    id: 4,
    title: t("menu.hebergement"),
    link: "/" + t("url.hebergement"),
    submenu: [
      {
        id: 31,
        title: "Hôtel avec petit-déjeuner",
        link: "/" + t("url.hebergement_type"),
      },
      {
        id: 32,
        title: "Appartement avec cuisine",
        link: "/" + t("url.hebergement_type"),
      },
    ],
  },
  {
    id: 5,
    title: t("menu.location"),
    link: "/" + t("url.location"),
  },
  {
    id: 6,
    title: t("menu.contact"),
    link: "/" + t("url.contact"),
  },
];

export const navetteDefaultValue = (date) => ({
  name: "",
  selection: "",
  direction: "",
  flightNumber: "",
  timeArrival: "",
  dateArrival: date,
});

export const dateFormat = "YYYY-MM-DD";

export const breakfastDefaultValue = (formik) => ({
  paxNumberBreakfast: formik.values.adults + formik.values.kids,
});

export const hotelTypes = [
  "rooms.hotel-avec-petit-dejeuner",
  "rooms.appartement-avec-cuisine",
];

export const slickSettings = {
  dots: false,
  arrows: true,
  speed: 1500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1200,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
