import { List, ListItem } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function SubMenuList({ item, ClickHandler }) {
  return (
    <List className="subMenu">
      <Fragment>
        {item.submenu.map((submenu) => {
          return (
            <ListItem key={submenu.id}>
              <Link to={submenu.link} onClick={ClickHandler} className="active" >
                {submenu.title}
              </Link>
            </ListItem>
          );
        })}
      </Fragment>
    </List>
  );
}
