import NavetteForm from "@/components/Reservation/ReservationForm/NavetteForm";
import { IoIosClose } from "react-icons/io";
export default function NavetteItem({ index, formik, navette }) {
  const removeTab = () => {
    const updatedNavettes = [...formik.values.navette.data];
    updatedNavettes.splice(index, 1);
    if (updatedNavettes.length < 1) {
      formik.setFieldValue("navette.checked", false);
    }
    formik.setFieldValue("navette.data", updatedNavettes);
  };

  return (
    <>
      <div className="m-3 p-3 position-relative border border-dark-subtle form_navete">
        <IoIosClose
          className="position-absolute top-0 end-0 cursor-pointer"
          size={"2rem"}
          color="red"
          onClick={removeTab}
        />
        <NavetteForm index={index} navette={navette} formik={formik} />
      </div>
    </>
  );
}
