import { configureStore } from "@reduxjs/toolkit";
import roomReducer from './slices/room-slice';
import hotelTypeReducer from './slices/hotel-type-slice';

export const store = configureStore({
	reducer: {
		room: roomReducer,
		hotelType: hotelTypeReducer
	},
});
