import React from "react";

export default function Copyright() {
    const year = new Date().getFullYear();
	return (
		<div className='wpo-lower-footer'>
			<div className='container'>
				<div className='row'>
					<div className='col col-xs-12'>
						<p className='copyright'>
							{" "}
							Copyright &copy; {year} Valiha Hôtel
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
