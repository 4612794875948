import { useReservationForm, useSearchReservation } from "@/hooks/reservation";
import { useTranslation } from "react-i18next";
import PersonalInformation from "./PersonalInformation";
import ReservationDetail from "./ReservationDetail";
import FormulaireForm from "./ReservationForm";
import BreakfastForm from "./ReservationForm/BreakfastForm";
import ScrollTo from "../ScrollTo";
import { ErrorScrollContext } from "@/utils/error-scroll";
import { useContext, useEffect } from "react";
import { scrollTo } from "@/utils/error-scroll";

const ReservationFormSection = () => {
  const { t } = useTranslation();
  const { formik } = useReservationForm();
  const { personalInfosErrorView, shuttleErrorView } =
    useContext(ErrorScrollContext);

  const handleButtonClick = () => {
    if (formik.errors.navette) {
      scrollTo(shuttleErrorView);
    } else if (formik.errors) {
      scrollTo(personalInfosErrorView);
    }
    window.localStorage.setItem(
      "reservationFormData",
      JSON.stringify(formik.values)
    );
    formik.handleSubmit();
  };

  useEffect(() => {
    const oldReservationFormData = localStorage.getItem("reservationFormData");
    if (oldReservationFormData) {
      const object = JSON.parse(oldReservationFormData);
      for (let key of Object.keys(object)) {
        if (key === "navette") {
          let navettesData = object[key].data;
          navettesData = navettesData.map((navette) => {
            navette.dateArrival = new Date(navette.dateArrival);
            return navette;
          });
          object[key].data = navettesData;
        }
        formik.setFieldValue(key, object[key]);
      }
    }
  }, []);

  return (
    <ScrollTo top={400}>
      <section className="wpo-reservation-pg-section my-11">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-lg-7">
              <form onSubmit={formik.handleSubmit} noValidate>
                <div className="wpo-reservation-form-area rounded-2 row shadow-sm border">
                  <PersonalInformation />
                  <BreakfastForm />
                  <div className="col col-12">
                    <FormulaireForm />
                    <div className="submit-area mt-4">
                      <button
                        className="theme-btn bValider"
                        type="button"
                        onClick={handleButtonClick}
                      >
                        {t("bouton.confirmation")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12 col-lg-5">
              <div
                className="sticky-lg-top shadow-sm border px-4 py-4 mb-4 rounded"
                style={{ top: 115 }}
              >
                <ReservationDetail />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollTo>
  );
};

export default ReservationFormSection;
