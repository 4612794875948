import { Collapse, ListItem } from "@mui/material";
import { Fragment, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import SubMenuList from "../SubMenuList";

export default function MenuListItem({ item, setMenuState }) {
  const [openId, setOpenId] = useState(0);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
    setMenuState(false);
  };

  return (
    <ListItem className={item.id === openId ? "active" : null} key={item.id}>
      {item.submenu ? (
        <Fragment>
          <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
            {item.title}
            <If condition={item.id === openId}>
              <Then>
                <FaAngleUp />
              </Then>
              <Else>
                <FaAngleDown />
              </Else>
            </If>
          </p>
          <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
            <SubMenuList item={item} ClickHandler={ClickHandler} />
          </Collapse>
        </Fragment>
      ) : (
        <Link className="active" to={item.link} onClick={ClickHandler}>
          {item.title}
        </Link>
      )}
    </ListItem>
  );
}
