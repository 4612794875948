import { useTranslation } from "react-i18next";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { lazy } from "react";
import ReservationPage from "@/main-component/ReservationPage/index";
import ReservationConfirmation from "@/pages/ReservationConfirmation";
import ReservationEmail from "@/pages/ReservationEmail";
import ReservationFormPage from "@/pages/ReservationFormPage";
import Layout from "@/components/Layout";

const Error = lazy(() =>
  import(/* webpackChunkName: "error" */ "@/components/404")
);
const BlogDetailsFull = lazy(() =>
  import(
    /* webpackChunkName: "blog-details-full" */ "@/main-component/BlogDetailsFull"
  )
);
const BlogDetails = lazy(() =>
  import(/* webpackChunkName: "blog-details" */ "@/main-component/BlogDetails")
);
const BlogDetailsLeftSide = lazy(() =>
  import(
    /* webpackChunkName: "blog-details-left" */ "@/main-component/BlogDetailsLeftSide"
  )
);
const BlogPage = lazy(() =>
  import(/* webpackChunkName: "blog-page" */ "@/main-component/BlogPage")
);
const BlogPageFullWidth = lazy(() =>
  import(
    /* webpackChunkName: "blog-page-fullwidth" */ "@/main-component/BlogPageFullwidth"
  )
);
const BlogPageLeft = lazy(() =>
  import(
    /* webpackChunkName: "blog-page-left" */ "@/main-component/BlogPageLeft"
  )
);
const ContactPage = lazy(() =>
  import(
    /* webpackChunkName: "contact-page" */ "@/main-component/ContactPage/ContactPage"
  )
);
const DetailLocationCar = lazy(() =>
  import(
    /* webpackChunkName: "detail-location-car" */ "@/main-component/DetailLocationCar"
  )
);
const FormPetitDej = lazy(() =>
  import(
    /* webpackChunkName: "form-petit-dej" */ "@/main-component/FormulairePetitDej/FormPetitDej"
  )
);
const Galerie = lazy(() =>
  import(/* webpackChunkName: "galerie" */ "@/main-component/Galerie")
);
const GalerieService = lazy(() =>
  import(
    /* webpackChunkName: "galerie-service" */ "@/main-component/GalerieService"
  )
);
const LocationCar = lazy(() =>
  import(/* webpackChunkName: "location-car" */ "@/main-component/LocationCar")
);
const RoomPage = lazy(() =>
  import(/* webpackChunkName: "room-page" */ "@/main-component/RoomPage")
);
const RoomSinglePage = lazy(() =>
  import(
    /* webpackChunkName: "room-single-page" */ "@/main-component/RoomSinglePage"
  )
);
const SearchResults = lazy(() =>
  import(
    /* webpackChunkName: "search-results" */ "@/main-component/SearchResults/SearchResults"
  )
);
const ServicePage = lazy(() =>
  import(
    /* webpackChunkName: "service-page" */ "@/main-component/ServicePage/ServicePage"
  )
);
const ServicePageS2 = lazy(() =>
  import(
    /* webpackChunkName: "service-page-s2" */ "@/main-component/ServicePageS2/ServicePageS2"
  )
);
const CategoryRoomsPage = lazy(() =>
  import(/* webpackChunkName: "category-rooms-page" */ "@/pages/CategoryRooms")
);
const Homepage = lazy(() =>
  import(/* webpackChunkName: "homepage" */ "@/pages/HomePage")
);
const LocationEmail = lazy(() =>
  import(/* webpackChunkName: "location-email" */ "@/pages/LocationEmail")
);
const LocationForm = lazy(() =>
  import(/* webpackChunkName: "location-form" */ "@/pages/LocationForm")
);

const ExternalBreakfast = lazy(() => import('@/pages/ExternalBreakfast'))

const AllRoute = () => {
  const { t, i18n } = useTranslation();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout withBannerImage={false} />}>
          <Route index element={<Homepage />} />
          <Route path={i18n.resolvedLanguage} element={<Homepage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path={t("url.reservation")} element={<ReservationPage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service-s2" element={<ServicePageS2 />} />
          <Route path="blog-single/:id" element={<BlogDetails />} />
          <Route
            path="blog-single-left-sidebar/:id"
            element={<BlogDetailsLeftSide />}
          />
          <Route
            path="blog-single-fullwidth/:id"
            element={<BlogDetailsFull />}
          />
          <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
          <Route path="blog-fullwidth" element={<BlogPageFullWidth />} />

          <Route path={t("url.form_petit_dej")} element={<FormPetitDej />} />
          <Route
            path={`${t("url.reservation")}/email`}
            element={<ReservationEmail />}
          />
          {/* ReservationPage */}
          <Route path={t("url.reserve_global")} element={<Homepage />} />
          <Route path={t("url.blog")} element={<BlogPage />} />
          <Route
            path={t("url.hebergement_dispo")}
            element={<SearchResults />}
          />
          <Route path={t("url.hebergement")} element={<RoomPage />} exact />
          <Route path={t("url.hebergement_type")} element={<RoomPage />} />
          <Route path={t("url.formulaire")} element={<ReservationFormPage />} />
          <Route
            path={`${t("url.formulaire")}/confirmation`}
            element={<ReservationConfirmation />}
          />
          <Route path={t("url.detail_chambre")} element={<RoomSinglePage />} />
          <Route path={t("url.service_type")} element={<GalerieService />} />
          <Route path={t("url.galerie")} element={<Galerie />} />

          <Route
            path={`${t("url.location")}/email`}
            element={<LocationEmail />}
          />
          <Route path="location-form" element={<LocationForm />} />

          <Route
            path={`${t("url.category.rooms")}`}
            element={<CategoryRoomsPage />}
          />
        </Route>
        <Route element={<Layout isFixed={true} withBannerImage={false} />}>
          <Route path={t("url.contact")} element={<ContactPage />} />
          <Route path={t("url.location")} element={<LocationCar />} />
          <Route
            path={`${t("url.location")}/detail`}
            element={<DetailLocationCar />}
          />
        </Route>
        
        <Route path="breakfast-service" element={<ExternalBreakfast />} />
        <Route path={i18n.resolvedLanguage}>
          <Route path="breakfast-service" element={<ExternalBreakfast />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoute;
