import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { Else, If, Then } from "react-if";

export default function ReservationEmail() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="d-flex flex-column message-container">
      <If condition={location.state?.sent ?? false}>
        <Then>
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div className="text-center">
            <h3>{t("reservations.email.title")}</h3>
            <p>{t("reservations.email.subTitle")}</p>
            <Link to="/" className="theme-btn">
              {t("homepage")}
            </Link>
          </div>
        </Then>
        <Else>
          <div className="error-banmark">
            <div className="ban-icon">
              <span className="icon-line line-long-invert"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div className="text-center">
            <h3 className="text-danger">{t("reservations.email.error")}</h3>
            <p>{t("reservations.email.errorMessage")}</p>
            <Link to="/" className="theme-btn">
              {t("homepage")}
            </Link>
          </div>
        </Else>
      </If>
    </div>
  );
}
