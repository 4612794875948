import { useTranslation } from "react-i18next";
import { FaLocationDot, FaEnvelope, FaPhone ,FaWhatsapp } from "react-icons/fa6";

export default function FooterContact() {
  const { t } = useTranslation();
  return (
    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
      <div className="widget wpo-service-link-widget px-4 ">
        <div className="widget-title">
          <h3>{t("menu.contact")}</h3>
        </div>
        <div className="contact-ft">
          <ul>
            <li>
              <div>
                <FaLocationDot size={24} />
                <span>{t("footer.contact.adresse")}</span>
              </div>
            </li>
            <li>
              <div>
                <FaPhone size={24} />
                <span>+261 38 98 368 02</span>
              </div>
            </li>
            <li>
              <div>
                <FaWhatsapp size={24} />
                <span>+261 38 98 368 02</span>
              </div>
            </li>
            <li>
              <div>
                <FaEnvelope size={24} />
                <span>contact@valihahotel.com</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
